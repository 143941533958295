
import { defineComponent } from 'vue';
import { Form, Field } from 'vee-validate';
import Datatable from '@/components/kt-datatable/KTDatatable.vue';
//import Swal from "sweetalert2/dist/sweetalert2.min.js";
// import * as Yup from "yup";
import ApiService from '@/core/services/ApiService';
import { apiEndpoint } from '@/mixin/apiEndpoint';
import { VueCookieNext } from 'vue-cookie-next';

export default defineComponent({
  mixins: [apiEndpoint],
  name: 'new-enrollment-bill-adjustment',
  components: {
    Form,
    Field,
    Datatable,
  },
  data() {
    return {
      batch: {
        tranche_id: '',
        entity_id: '',
        bill_no: '',
        claim_no: 1,
        bill_date: '',
      },
      entityInfos: [],
      billList: [] as any,
      loading: false,
      billNewData: [] as any,
      allBillNo: [],
      tranches_info: [],
      empDistricts: [],
      showCourseNotice: false,
      tableData: [],
      tableHeader: [
        {
          name: 'TI',
          key: 'ti',
          sortable: true,
          width: '5px',
        },
        {
          name: 'Course',
          key: 'course',
          sortable: true,
        },
        {
          name: 'BN',
          key: 'bn',
          sortable: true,
        },
        {
          name: 'Trainee Count',
          key: 'trainee_count',
          sortable: true,
        },
        {
          name: 'Unit Cost',
          key: 'unit_cost',
          sortable: true,
        },

        {
          name: 'Pay %',
          key: 'pay_percent',
          sortable: true,
        },
        {
          name: 'Payment Amount	',
          key: 'payment_amount',
          sortable: true,
        },
        {
          name: 'Bill Amount	',
          key: 'bill_amount',
          sortable: true,
        },
        {
          name: 'New Unit Cost',
          key: 'new_unit_cost',
          sortable: true,
        },
        {
          name: 'New Payment Amount',
          key: 'new_payment_amount',
          sortable: true,
        },
        {
          name: 'New Bill Amount',
          key: 'new_bill_amount',
          sortable: true,
        },
        {
          name: 'Adj. Amount',
          key: 'adj_amount',
          sortable: true,
        },
      ],
    };
  },
  async created() {
    await this.getTranches();
    await this.getAssociation();
  },
  methods: {
    changeValue(data, i) {
      this.billList[i].new_payment_amount =
        (data * this.billList[i].pay_percentage) / 100;
      this.billList[i].new_bill_amount =
        this.billList[i].new_payment_amount * this.billList[i].trainee_count;
      this.billList[i].adjustment_amount =
        this.billList[i].new_bill_amount - this.billList[i].bill_amount;
    },
    async formSubmit() {
      this.loading = true;
      let data = new FormData();
      data.set('data', JSON.stringify(this.billList));
      ApiService.post('', data)
        .then((response) => {
          this.loading = false;
          console.log(response);
        })
        .catch((response) => {
          this.loading = false;
          console.log(response);
        });
    },
    async getTranches() {
      await ApiService.get(this.VUE_APP_TRANCHE_LIST_API)
        .then((response) => {
          this.tranches_info = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getAssociation() {
      let entity_id = '';
      if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
        entity_id = VueCookieNext.getCookie('_entity_id');
      }
      await ApiService.get('entity/list?entity_id=' + entity_id)
        .then((response) => {
          this.entityInfos = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getAllBillNo() {
      await ApiService.get(
        'bill/claim_1/all_bill_no?entity_id=' +
          this.batch.entity_id +
          '&tranche_id=' +
          this.batch.tranche_id +
          '&active_status=2'
      )
        .then((response) => {
          this.allBillNo = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getAdjustmentBill() {
      await ApiService.get(
        'bill/claim_1/adjustment-bill?entity_id=' +
          this.batch.entity_id +
          '&tranche_id=' +
          this.batch.tranche_id +
          '&bill_date=' +
          this.batch.bill_date +
          'bill_no=' +
          this.batch.bill_no +
          '&claim_no=1'
      )
        .then((response) => {
          this.billList = response.data.data;
          for (let i = 0; i < this.billList.length; i++) {
            this.billList[i].new_unit_cost =
              this.billList[i].course_info.unit_cost_total;
            this.billList[i].new_payment_amount =
              (this.billList[i].new_unit_cost *
                this.billList[i].pay_percentage) /
              100;
            this.billList[i].new_bill_amount =
              this.billList[i].new_payment_amount *
              this.billList[i].trainee_count;
            this.billList[i].adjustment_amount =
              this.billList[i].new_bill_amount - this.billList[i].bill_amount;
          }
          // console.log(this.billList);
          this.showCourseNotice = true;

          console.log(this.billNewData);
          //console.log(response);
        })
        .catch((response) => {
          console.log(response);
        });
    },
    // courseNotice() {
    //   this.showCourseNotice = true;
    // },
    // trainingProviderNotice() {
    //   this.showtrainingProviderNotice = true;
    // },
  },
  setup() {
    const AssociationSchema = [];
    return {
      AssociationSchema,
    };
  },
});
